body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}





 /* .menu-ul{
    list-style-type: none;
    margin: 0;
}

.menu-ul li{
    display: inline-block;
    margin-left: 10px;
} */


.header{
    background-image: url(/static/media/court2.ab0d310b.jpg);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
} 


/* style="background-image: url(http://mrmrs.github.io/photos/u/011.jpg)";
style="fill:currentcolor" */
